<template>
  <BasicModal
    action-title="Delete"
    :action="deleteUserContract"
    :disable-action-button="isLoading"
    :action-button-classes="['danger']"
  >
    <div class="header">
      <ErrorIcon />
      <h4>Delete contract?</h4>
    </div>
    <p>
      Are you sure you want to delete <strong>{{ userContract.name }}</strong>?
    </p>
    <template v-if="isLoading">
      <div class="mt-6 flex items-center">
        <LoadingIcon class="mr-2" size="small" /> Loading associated patches...
      </div>
    </template>
    <template v-else>
      <div class="mt-6">
        <template v-if="associatedPatches.length === 0">
          <p>
            This contract is not being used in any Patches, so nothing else will
            be deleted.
            <br>
          </p>
        </template>
        <template v-else>
          <p>
            <strong>The following {{ associatedPatches.length > 1 ? `${associatedPatches.length} Patches` : 'Patch' }} will be deleted:</strong>
          </p>
          <ul>
            <li
              :key="associatedPatch.id"
              v-for="associatedPatch in associatedPatches"
            >
              {{ associatedPatch.name }}
            </li>
          </ul>
        </template>
        <br>
        <strong class="text-danger-700">This action is permanent and cannot be undone.</strong>
      </div>
    </template>
  </BasicModal>
</template>

<script>

  import ErrorIcon from '@/assets/icons/error.svg'

  import BasicModal from '@/components/modals/_BasicModal.vue'

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'

  export default {
    components: {
      ErrorIcon,
      BasicModal,
      LoadingIcon,
    },
    props: {
      userContract: {
        type: Object,
        required: true,
      },
      onSuccess: {
        type: Function,
        required: true,
      },
    },
    data() {
      return {
        isLoading: true,
        associatedPatches: [],
      }
    },
    created() {

      this.isLoading = true

      this.$store.state.api.dispatch
        .get(`/account/contracts/${this.userContract.id}/associations`, { params: { size: 2 ** 32 } })
        .then((response) => {
          this.associatedPatches = response.data
        })
        .finally(() => {
          // @NOTE: we add a slight delay here since the associations load is
          //  almost instant and it's a bit weird to have the loading text flash
          //  and dissapear before the user can read it
          setTimeout(() => {
            this.isLoading = false
          }, 500)
        })

    },
    methods: {
      deleteUserContract() {
        return this.$store.dispatch('api/DELETE_USER_CONTRACT', this.userContract.id)
          .then(() => {
            return Promise.all([
              this.$store.dispatch('user/REFRESH_USER_CONTRACTS'),
              this.$store.dispatch('pagination/LOAD_PAGE', { listName: 'userContracts' }),
            ])
          })
          .then(() => {
            this.onSuccess()
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Contract deleted!',
              type: 'success',
            })
          })
          .catch(() => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Could not delete contract!',
              type: 'error',
            })
          })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  .header
    @apply mb-6
    @apply space-x-2

    @apply flex
    @apply items-center

    h4
      @apply mb-0

    svg
      @apply w-8
      @apply h-8
      @apply text-danger-700

  ul
    @apply mt-6

</style>
